.sponsors-wrapper {
  background: #ffffff;
  font-family: 'Gotham-Light';
  min-height: 150px;
  width: 100%;

  .sponsors-text {
    padding: 2em 0em 0em 3em;
  }

  .sponsors-images-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0em 3em;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 1em;

    .sponsor-image-wrapper {
      height: 100px;
      width: 300px;

      .lmr {
        height: 100px;
        width: 300px;
        overflow: hidden;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../../assets/Logo_LMR_4c.jpg');
      }

      .kultur {
        height: 100px;
        width: 300px;
        overflow: hidden;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../../assets/AK_Kultur\ und\ Wissenschaft_Farbig_CMYK.jpg');
      }

      .buro {
        height: 100px;
        width: 300px;
        overflow: hidden;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../../assets/Logo_Kulturburo.jpg');
      }
    }
  }
}
