$backgroundColor: #EDECEC;
$orange: #E67015;

.construction-wrapper {
  align-items: center;
  color: $backgroundColor;
  display: flex;
  flex-direction: column;
  font-family: 'Gotham-Medium';
  height: 100vh;
  justify-content: center;
  padding-top: 10em;

  .homepage-video-wrapper {
    padding: 3em 3em 6em 3em;

    .homepage-video-header {
      color: $orange;
      display: flex;
      justify-content: center;
      width: 50vw;
    }

    .homepage-video-container {
      overflow: hidden;
      padding-top: 56.25%;
      position: relative;
      width: 100%;

      .homepage-video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .construction-wrapper {
    padding-top: 3em;
    .homepage-video-wrapper {
      .homepage-video-header {
        width: 90vw;
      }
    }
  }
}