$backgroundColor: #EDECEC;

.event-wrapper {
  align-items: center;
  color: $backgroundColor;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 5em;
  width: 100%;

  .event-img-wrapper {
    .event-img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 400px;
      width: 100%;

      &.first {
        background-image: url('../../../src/assets/Location-1.png');
      }

      &.second {
        background-image: url('../../../src/assets/Location-2.png');
      }
    }
  }

  .event-img-wrapper, .event-text-wrapper {
    flex-basis: 40%;
  }

  .event-text-wrapper {
    text-align: center;

    .event-text-link {
      color: $backgroundColor;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
        color: lightgray;
      }
    }

    .event-text-address, .event-text-details-event {
      font-family: 'Gotham-Light';
    }

    .event-text-details {
      padding-top: 1.5em;
      
      &.second {
        margin-top: 5em;
      }
    }

    .event-text-details, .event-text-address {
      line-height: 1.25em;
    }
  }
}

@media screen and (max-width: 500px) {
  .event-wrapper {
    flex-direction: column;

    .event-img-wrapper {
      justify-content: center;
      display: flex;
      width: 100%;

      .event-img {
        height: 250px;
        width: 80vw;
      }
    }
  }
}