$backgroundColor: #EDECEC;
$orange: #E67015;

.navbar {
  .navbar-back-btn {
    height: 75%;
    margin-right: auto;
    padding-left: 2em;

    &:hover {
      opacity: .7;
      cursor: pointer;
    }
  }
  .navbar-text-logo-wrapper {
    align-items: flex-end;
    background: black;
    border-bottom: 1px solid $backgroundColor;
    display: flex;
    height: auto;
    font-family: 'Gotham-Light';
    justify-content: flex-end;
    padding-bottom: 1em;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;

    .navbar-btn-wrapper {
      display: flex;
      flex-direction: row;

      .navbar-btn {
        align-items: center;
        border: 1px solid $backgroundColor;
        color: $backgroundColor;
        cursor: pointer;
        display: flex;
        font-weight: bold;
        justify-content: center;
        padding-bottom: .5em;
        padding-top: .5em;
        width: 3em;

        &.de {
          margin-right: 1em;
          margin-left: 1em;
        }

        &.en {
          margin-right: 7em;
        }

        &.selected {
          color: $orange;
          border: 1px solid $orange;
        }

        &.not-selected {
          opacity: .5;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .navbar-logo-wrapper {
      cursor: pointer;
      padding-right: 2em;
      padding-top: 1em;
    }
  }
}