.hotel-wrapper {
  text-align: center;

  &:last-child {
    margin-left: auto;
    margin-right: auto;
  }

  .hotel-address {
    color: black;
    text-decoration: none;
    padding-bottom: 2em;

    &:hover {
      opacity: .5;
    }
  }

  .txt {
    font-family: 'Gotham-Light';
    text-decoration: underline;
  }

  .hotel-contact {
    padding-bottom: 5em;
    padding-top: 2em;
    text-decoration: none;

    .hotel-email {
      padding-bottom: 2em;
    }

    .hotel-url {
      color: black;
      
      &:hover {
        opacity: .5;
      }
    }
  }
}