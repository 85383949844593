$backgroundColor: #edecec;
$orange: #e67015;

.btn {
  align-items: center;
  background: #e67015;
  border: 1px solid black;
  border-radius: 5px;
  color: $backgroundColor;
  cursor: pointer;
  display: flex;
  height: 6em;
  justify-content: center;
  margin: 0.5em 0.25em;

  &.nm-b {
    margin: 0.5em 0.25em 0em 0.25em;
    @media screen and (max-width: 1260px) {
      margin: 0.5em 0.25em 0em 0.25em;
    }
  }

  .btn-text-wrapper {
    font-weight: 'Gotham-Bold';
    text-align: center;
  }
}

@media screen and (min-width: 500px) {
  .btn {
    &:hover {
      border: 1px solid $backgroundColor;
      background: black;
      color: $orange;
    }
  }
}

@media screen and (max-width: 500px) {
  .btn {
    &:focus {
      border: 1px solid $backgroundColor;
      background: black;
      color: $orange;
    }
  }
}
