$backgroundColor: #edecec;
$orange: #e67015;

.travel-wrapper {
  margin-top: 3em;

  .travel-header-wrapper {
    color: $backgroundColor;
    display: flex;
    flex-direction: column;
    font-family: 'Gotham-Medium';
    padding-bottom: 7em;
    padding-top: 6em;
    text-align: center;
  }

  .travel-events-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: 'Gotham-Medium';
    justify-content: center;
  }

  .travel-hotels-wrapper {
    align-items: center;
    background: $backgroundColor;
    display: flex;
    flex-direction: column;
    font-family: 'Gotham-Medium';
    justify-content: center;
    width: 100%;

    .travel-hotels-title {
      padding-bottom: 1em;
      padding-top: 1em;
    }

    .travel-hotels-info-txt {
      line-height: 1.5em;
      text-align: center;
      width: 75%;

      .travel-link {
        color: black;
        text-decoration: underline;
      }
    }

    .travel-hotels-info-txt-price {
      color: $orange;
      font-family: 'Gotham-Bold';
      padding-top: 2em;
      padding-bottom: 1em;
      text-align: center;
    }

    h3.travel-hotels-info-txt {
      margin-bottom: 0em;
    }

    .last {
      padding-bottom: 8em;
    }

    .travels-hotel-list-wrapper {
      display: flex;
      flex-flow: row wrap;
      padding-top: 2em;
      width: 100%;

      .hotel-wrapper {
        flex-basis: 50%;
      }
    }
  }

  .accommodations-wrapper {
    align-items: center;
    color: $backgroundColor;
    display: flex;
    flex-direction: column;
    font-family: 'Gotham-Medium';
    justify-content: center;
    text-align: center;

    .accommodations-title {
      padding-top: 2em;
    }

    .accommodations-with {
      padding-top: 2em;
    }

    .accommodations-txt {
      padding-top: 1em;
      padding-bottom: 1em;

      .accommodations-link {
        color: $orange;
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .small {
      width: 80%;
    }

    .last {
      padding-top: 4em;
    }

    &:last-child {
      padding-bottom: 4em;
    }
  }
}

.orange {
  color: $orange;
  padding-bottom: 2em;
}

@media screen and (max-width: 655px) {
  .travel-wrapper {
    .travel-header-wrapper {
      padding: 3em 2em;
    }
    .travel-hotels-wrapper {
      padding: 0em;
      .travels-hotel-list-wrapper {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .event-wrapper {
    .event-text-wrapper {
      .event-text-details.second {
        margin-top: 1em;
      }
    }
  }
}
