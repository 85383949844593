.tickets-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
}

@media screen and (max-width: 500px) {
  .tickets-wrapper {

  }
}