$backgroundColor: #edecec;
$orange: #e67015;

.fl-2 {
  font-size: 2em;
}

.ta-c {
  text-align: center;
}

.width8 {
  width: 80%;
}

.under {
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
}

.black {
  color: black;
}

.mtb-1 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.mlr-1 {
  margin-right: 1em;
  margin-left: 1em;
}

.mtb-half {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.mt-2 {
  margin-top: 2em;
}

.mr-1 {
  margin-right: 1em;
}

.mr-2 {
  margin-right: 2em;
}

.mr-3 {
  margin-right: 3em;
}

.ml-1 {
  margin-left: 1em;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.mb-3 {
  margin-bottom: 3em;
}

.mt-1 {
  margin-top: 1em;
}

.mt-0 {
  margin-top: 0em;
}

.fg-1 {
  flex-grow: 1;
}

.byc-pp-txt {
  color: $orange;
}

.byc-img-txt-wrapper {
  display: flex;
  flex-direction: row;
  padding-bottom: 2em;
  align-items: center;

  @media screen and (max-width: 1070px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .byc-img-wrapper {
      margin-bottom: 3em;
      margin-right: 0em;
      @media screen and (max-width: 450px) {
        margin-bottom: 2em;
      }
    }
  }

  .byc-img-wrapper {
    height: 400px;
    width: 650px;

    @media screen and (max-width: 660px) {
      width: 440px;
    }

    @media screen and (max-width: 500px) {
      width: 340px;
      height: 300px;
    }

    .byc-img {
      background-image: url('../../../src/assets/BYC-BMF-2024.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      height: 400px;
      width: 100%;

      @media screen and (max-width: 660px) {
        width: 440px;
      }

      @media screen and (max-width: 500px) {
        height: 300px;
        width: 340px;
      }
    }
  }

  .byc-pp-txt-wrapper {
    display: flex;
    flex-direction: row;

    .byc-pp-txt-wrapper,
    .byc-pp-img-wrapper {
      display: flex;
      flex-direction: column;
    }

    .byc-pp-txt-img-wrapper {
      .byc-pp-img-wrapper {
        height: 200px;
        width: 200px;

        .byc-pp-img {
          background-image: url('../../../src/assets/paypal-code.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% auto;
          height: 200px;
          width: 100%;
        }
      }
    }
  }
}

.underline {
  &:hover {
    cursor: pointer;
    text-decoration: underline $orange;
    opacity: 0.7;
  }
}

.home-wrapper {
  display: flex;
  font-family: 'Gotham-Medium';
  font-size: 1.25em;
  justify-content: center;
  flex-direction: column;

  .homepage-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;

    &.de {
      background-image: url('../../../src/assets/KeyVisual_large.png');
    }

    &.en {
      background-image: url('../../../src/assets/KeyVisual_large_EN.png');
    }
  }

  .homepage-welcome-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    color: $backgroundColor;
    text-align: center;
    width: 60%;

    .homepage-txt-wrapper,
    .homepage-header-txt {
      margin-bottom: 2em;
    }

    .homepage-header-txt {
      font-family: 'Gotham-Medium';
    }
  }

  .homepage-program-wrapper {
    align-items: center;
    background-color: $backgroundColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // margin-top: 3em;
    width: 100%;

    .homepage-program-header {
      background-color: $backgroundColor;
      color: $orange;
      font-family: 'Gotham-Medium';
      padding: 1em;
    }

    .homepage-program-header-txt {
      line-height: 1.5em;
      text-align: center;
      width: 75%;

      &.last {
        line-height: 1.5em;
        text-align: center;
        width: 75%;
        padding: 2em;
      }
    }

    .homepage-program-days-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;

      .homepage-program-day-wrapper {
        text-align: center;

        .homepage-program-dow {
          margin-bottom: 0em;
          margin-top: 0em;

          &.date {
            margin-top: 0.25em;
            margin-bottom: 2em;
          }
        }

        .homepage-program-content {
          font-family: 'Gotham-Light';

          &.top {
            padding-top: 1em;
          }
        }

        .homepage-program-content-line {
          border-top: 2px dashed black;
          margin-left: auto;
          margin-right: auto;
          margin: 3em 3em;
        }
      }
    }

    .homepage-program-footer-wrapper {
      display: flex;
      flex-direction: row;
      font-weight: bold;
      justify-content: space-around;
      padding-top: 5em;
      padding-bottom: 5em;
      width: 100%;

      .homepage-program-footer-pdf {
        color: black;
        text-decoration: underline;

        &:hover {
          cursor: pointer;
          opacity: 0.5;
        }
      }
    }
  }

  .homepage-btn-first-wrapper {
    margin-left: 1em;
    margin-right: 1em;
  }

  .homepage-btn-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-bottom: 4em;
    margin-left: 1em;
    margin-right: 1em;

    @media screen and (max-width: 1260px) {
      justify-content: center;
    }

    .btn {
      flex-basis: 30%;

      &.mt-0 {
        margin-top: 0em;
      }
    }
  }

  .homepage-video-wrapper {
    background-color: black;
    padding: 3em 3em 6em 3em;

    .homepage-video-header {
      color: $orange;
      display: flex;
      justify-content: center;
      margin-bottom: 2em;
    }

    .homepage-video-container {
      overflow: hidden;
      padding-top: 56.25%;
      position: relative;
      width: 100%;

      .homepage-video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .homepage-orange-bar {
    background-color: $orange;
    height: 1.5em;
    margin-bottom: 1em;
    width: 100vw;
  }

  .homepage-spreadshirt-wrapper {
    align-items: center;
    color: $backgroundColor;
    display: flex;
    flex-direction: column;
    text-align: center;

    &.guest {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      text-align: center;
      width: 60%;
      margin-bottom: 5em;
    }

    .spreadshirt {
      padding-bottom: 1em;
    }

    .homepage-spreadshirt-txt-wrapper {
      font-family: 'Gotham-Light';
      margin: 1em 0em;
      width: 90vw;

      .homepage-spreadshirt-txt {
        margin-bottom: 0.25em;
        margin-top: 0.25em;
      }

      .homepage-spreadshirt-link {
        text-decoration: underline;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .homepage-spreadshirt-img-wrapper {
      display: flex;
      flex-direction: row;
      padding-top: 3em;
      padding-bottom: 1em;

      .homepage-guest-img {
        background-position: center;
        background-image: url('../../assets/Clementones.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        height: 530px;
        width: 80vw;
      }

      .homepage-spreadshirt-img {
        background-position: center;
        background-image: url('../../assets/Spreadshirt.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 530px;
        width: 100vw;
      }
    }

    .homepage-spreadshirt-cc-txt {
      font-family: 'Gotham-Light';
      font-size: 12px;
      padding-bottom: 3em;
    }
  }

  .homepage-program-header {
    color: $orange;
    padding-bottom: 2em;
    padding-top: 1em;
  }

  .homepage-team-wrapper {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 3em;

    .teammember-wrapper {
      flex-basis: 30%;
      padding: 5px;
    }
  }
}

@media screen and (min-width: 1300px) {
  .home-wrapper {
    .homepage-img {
      height: 100vh;
    }
  }
}

@media screen and (max-width: 1300px) {
  .home-wrapper {
    .homepage-img {
      height: 75vh;
    }

    .homepage-spreadshirt-wrapper {
      .homepage-spreadshirt-img-wrapper {
        .homepage-spreadshirt-img {
          background-size: contain;
          height: 530px;
        }
        padding-bottom: 0em;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .home-wrapper {
    .homepage-program-wrapper {
      .homepage-program-header {
        padding-bottom: 0em;
      }

      .homepage-program-days-wrapper {
        flex-direction: column;
        .homepage-program-day-wrapper {
          padding-top: 3em;

          .homepage-program-dow {
            margin-top: 1em;
          }

          .homepage-program-content-line {
            margin-left: auto;
            margin-right: auto;
            width: 50%;
          }
        }
      }

      .homepage-program-footer-wrapper {
        width: 80%;
        :last-child {
          padding-left: 2em;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .home-wrapper {
    display: flex;
    justify-content: center;

    .homepage-img {
      background-position: top;
      background-repeat: no-repeat;
      background-size: 100% auto;
      margin-top: 2em;
      width: 100%;
      height: 45vh;

      &.de {
        background-image: url('../../../src/assets/KeyVisual_mobile.png');
      }

      &.en {
        background-image: url('../../../src/assets/KeyVisual_mobile_EN.png');
      }
    }

    .homepage-welcome-wrapper {
      font-size: 1em;
      width: 80%;
    }

    .homepage-program-wrapper {
      .homepage-program-days-wrapper {
        flex-direction: column;

        :first-child {
          margin-top: 0em;
          padding-top: 0em;
        }

        .homepage-program-day-wrapper {
          padding-top: 1em;

          .homepage-program-dow {
            margin-top: 2em;
          }
        }
      }

      .homepage-program-footer-wrapper {
        display: flex;
        flex-direction: column;
        text-align: center;

        :nth-child(2) {
          padding-top: 1em;
        }
      }
    }

    .homepage-btn-wrapper {
      display: block;
    }

    .homepage-video-wrapper {
      padding: 3em 1em 6em 1em;

      .homepage-video-header {
        padding-left: 2em;
        padding-right: 2em;
      }
    }

    .homepage-spreadshirt-wrapper {
      &.guest {
        font-size: 1em;
        width: 90vw;
      }

      .homepage-spreadshirt-img-wrapper {
        &.guest {
          padding-top: 2em;
        }

        .homepage-guest-img {
          height: 250px;
        }
        .homepage-spreadshirt-img {
          background-size: contain;
          height: 200px;
          padding-bottom: 0em;
        }
      }
    }
  }
}

.bold {
  font-family: 'Gotham-Bold';
}
