$backgroundColor: #edecec;
$orange: #e67015;

.strike {
  text-decoration: line-through;
}

.orange {
  color: $orange;
  padding-bottom: 0em !important;
}

.ref-link {
  color: $orange;
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

.light {
  font-family: 'Gotham-Light';
}

.white {
  color: white;
}

.ital {
  font-style: italic;
}

.normal {
  font-style: normal;
}

.mb-2 {
  margin-bottom: 2em;
}

.mt-3 {
  margin-top: 3em;
}

.champ-wrapper {
  color: white;
  display: flex;
  font-family: 'Gotham-Medium';
  font-size: 1.25em;
  justify-content: center;
  flex-direction: column;
  padding-top: 10em;

  .champ-welcome-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    color: $backgroundColor;
    text-align: center;
    width: 75%;

    .champ-subheader-txt {
      margin-top: 5em;
      margin-bottom: 3em;

      &.mb-0 {
        margin-bottom: 2em;
      }
    }

    .champ-txt-wrapper,
    .champ-header-txt {
      margin-bottom: 2em;
    }

    .champ-header-txt {
      font-family: 'Gotham-Medium';
    }
  }

  .champ-program-wrapper {
    align-items: center;
    background-color: $backgroundColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3em;
    width: 100%;

    .champ-program-header {
      background-color: $backgroundColor;
      color: $orange;
      font-family: 'Gotham-Medium';
      padding: 1em;
    }

    .champ-program-header-txt {
      line-height: 1.5em;
      text-align: center;
      width: 75%;

      &.last {
        line-height: 1.5em;
        text-align: center;
        width: 75%;
        padding: 2em;
      }
    }

    .champ-program-days-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 100%;

      .champ-program-day-wrapper {
        text-align: center;

        .champ-program-dow {
          margin-bottom: 0em;
          margin-top: 0em;

          &.date {
            margin-top: 0.25em;
            margin-bottom: 2em;
          }
        }

        .champ-program-content {
          font-family: 'Gotham-Light';

          &.top {
            padding-top: 1em;
          }
        }

        .champ-program-content-line {
          border-top: 2px dashed black;
          margin-left: auto;
          margin-right: auto;
          margin: 3em 3em;
        }
      }
    }

    .champ-program-footer-wrapper {
      display: flex;
      flex-direction: row;
      font-weight: bold;
      justify-content: space-around;
      padding-top: 5em;
      padding-bottom: 5em;
      width: 100%;

      .champ-program-footer-pdf {
        color: black;
        text-decoration: underline;

        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }

  .champ-btn-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding-bottom: 4em;
    padding-top: 4em;

    .btn {
      flex-basis: 30%;
    }
  }

  .champ-video-wrapper {
    background-color: #edecec;
    padding: 3em 3em 6em 3em;

    .champ-video-header {
      color: $orange;
      display: flex;
      justify-content: center;
      margin-bottom: 2em;
    }

    .champ-video-container {
      overflow: hidden;
      padding-top: 56.25%;
      position: relative;
      width: 100%;

      .champ-video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .champ-orange-bar {
    background-color: $orange;
    height: 1.5em;
    margin-bottom: 1em;
    width: 100vw;
  }

  .champ-spreadshirt-wrapper {
    align-items: center;
    color: $backgroundColor;
    display: flex;
    flex-direction: column;
    text-align: center;

    &.guest {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: center;
      text-align: center;
      width: 60%;
      margin-bottom: 5em;
    }

    .spreadshirt {
      padding-bottom: 1em;
    }

    .champ-spreadshirt-txt-wrapper {
      font-family: 'Gotham-Light';
      margin: 1em 0em;
      width: 90vw;

      .champ-spreadshirt-txt {
        margin-bottom: 0.25em;
        margin-top: 0.25em;
      }

      .champ-spreadshirt-link {
        text-decoration: underline;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .champ-spreadshirt-img-wrapper {
      display: flex;
      flex-direction: row;
      padding-top: 3em;
      padding-bottom: 1em;

      .champ-guest-img {
        background-position: center;
        background-image: url('../../assets/Clementones.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        height: 530px;
        width: 80vw;
      }

      .champ-spreadshirt-img {
        background-position: center;
        background-image: url('../../assets/Spreadshirt.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 530px;
        width: 100vw;
      }
    }

    .champ-spreadshirt-cc-txt {
      font-family: 'Gotham-Light';
      font-size: 12px;
      padding-bottom: 3em;
    }
  }

  .champ-program-header {
    color: $orange;
    padding-bottom: 2em;
    padding-top: 1em;
  }

  .champ-team-wrapper {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 3em;

    .teammember-wrapper {
      flex-basis: 30%;
      padding: 5px;
    }
  }
}

@media screen and (min-width: 1300px) {
  .home-wrapper {
    .champ-img {
      height: 100vh;
    }
  }
}

@media screen and (max-width: 1300px) {
  .home-wrapper {
    .champ-img {
      height: 75vh;
    }

    .champ-spreadshirt-wrapper {
      .champ-spreadshirt-img-wrapper {
        .champ-spreadshirt-img {
          background-size: contain;
          height: 530px;
        }
        padding-bottom: 0em;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .home-wrapper {
    .champ-program-wrapper {
      .champ-program-header {
        padding-bottom: 0em;
      }

      .champ-program-days-wrapper {
        flex-direction: column;
        .champ-program-day-wrapper {
          padding-top: 3em;

          .champ-program-dow {
            margin-top: 1em;
          }

          .champ-program-content-line {
            margin-left: auto;
            margin-right: auto;
            width: 50%;
          }
        }
      }

      .champ-program-footer-wrapper {
        width: 80%;
        :last-child {
          padding-left: 2em;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .home-wrapper {
    display: flex;
    justify-content: center;

    .champ-img {
      background-position: top;
      background-repeat: no-repeat;
      background-size: 100% auto;
      margin-top: 2em;
      width: 100%;
      height: 45vh;

      &.de {
        background-image: url('../../../src/assets/KeyVisual_mobile.png');
      }

      &.en {
        background-image: url('../../../src/assets/KeyVisual_mobile_EN.png');
      }
    }

    .champ-welcome-wrapper {
      font-size: 1em;
      width: 80%;
    }

    .champ-program-wrapper {
      .champ-program-days-wrapper {
        flex-direction: column;

        :first-child {
          margin-top: 0em;
          padding-top: 0em;
        }

        .champ-program-day-wrapper {
          padding-top: 1em;

          .champ-program-dow {
            margin-top: 2em;
          }
        }
      }

      .champ-program-footer-wrapper {
        display: flex;
        flex-direction: column;
        text-align: center;

        :nth-child(2) {
          padding-top: 1em;
        }
      }
    }

    .champ-btn-wrapper {
      display: block;
    }

    .champ-video-wrapper {
      padding: 3em 1em 6em 1em;

      .champ-video-header {
        padding-left: 2em;
        padding-right: 2em;
      }
    }

    .champ-spreadshirt-wrapper {
      &.guest {
        font-size: 1em;
        width: 90vw;
      }

      .champ-spreadshirt-img-wrapper {
        &.guest {
          padding-top: 2em;
        }

        .champ-guest-img {
          height: 250px;
        }
        .champ-spreadshirt-img {
          background-size: contain;
          height: 200px;
          padding-bottom: 0em;
        }
      }
    }
  }
}

.bold {
  font-family: 'Gotham-Bold';
}
