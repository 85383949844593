$backgroundColor: #EDECEC;
$orange: #E67015;

.footer-wrapper {
  border-top: 1px solid $backgroundColor;
  color: $backgroundColor;
  display: flex;
  flex-direction: row;
  flex-flow: row nowrap;
  font-family: 'Gotham-Medium';
  line-height: 1.5em; 
  width: 100vw;

  .footer-left {
    .narrow {
      width: 75%;
    }
  }

  .footer-right {
    display: flex;
    flex-flow: row wrap;

    .footer-leftside {
      padding-right: 3em;
    }
    
    .footer-logo-wrapper {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-top: 1em;

      .footer-fb, .footer-yt, .footer-flickr {
        padding-bottom: 1em;

        &:hover {
          opacity: .5;
        }
      }
    }
    
    .footer-ideler {
      color: $backgroundColor;
      font-family: 'Gotham-Light';
      text-decoration: underline;

      &:hover {
        color: lightgray;
      }
    }
  }

  .footer-left{
    padding: 2em 3em;
  }

  .footer-right {
    padding: 2em 0em;
  }
}

.side {
  justify-content: center;
  flex-basis: 46%;
}

.footer-space-top {
  margin-top: 2em;

  .bold {
    font-family: 'Gotham-Bold';
  }
}

.hp-btn {
  font-family: 'Gotham-Light';
}

.footer-impressum {
  color: $backgroundColor;
  font-family: 'Gotham-Light';
  
  .impressum-txt{
    padding-top: 2em;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: $orange;
  }
}

.footer-btn {
  height: 48px;
  margin-left: 0em;
  margin-top: 2em;
}

a {
  text-decoration: none;
}

.light {
  font-family: 'Gotham-Light';
}

@media screen and (max-width: 750px) {
  .footer-wrapper {
    flex-direction: column;

    .footer-left, .footer-right {
      align-items: flex-start;
    }

    .side {
      display: block;
    }

    .footer-right {
      padding: 2em 3em;

      .footer-logo-wrapper {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 1em;
        
        .footer-fb, .footer-yt, .footer-flickr {
          padding: 0em;
        }
      }

      .footer-ideler > div {
        padding-top: 1em;
      }
    }

    .footer-left {
      .narrow {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1303px) {
  .footer-wrapper {
    .footer-right {
      .footer-leftside {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .footer-last-child {
          margin-top: auto;
          .footer-logo-wrapper {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding-top: 1em;
    
            .pdg-left {
              padding-left: 2em;
            }
          }
        }
      }
    }
  }
  .side {
    flex-basis: 70%;
  }
}