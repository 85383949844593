$backgroundColor: #EDECEC;
$borderRadius: 5px;
$orange: #E67015;

.teammember-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;

  .teammember-image-txt-wrapper {
    margin: 0em 0em 3em 0em;

    .teammember-image {
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: $borderRadius;
      height: 300px;
      width: 400px;
    }

    .teammember-image-orange {
      background-color: $orange;
      border-radius: $borderRadius;
      color: $backgroundColor;
      display: flex;
      flex-direction: column;
      height: 300px;
      justify-content: center;
      text-align: center;
      width: 400px;

      .teammember-title {
        margin-top: 0;
      }

      .teammember-info {
        font-family: 'Gotham-Light';
        margin: 0em 2em;
      }

      .teammember-email {
        font-family: 'Gotham-Medium';
      }
    }
  
    .teammember-txt-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      
      .teammember-name {
        font-family: 'Gotham-Bold';
        padding-top: 1em;
      }

      .teammember-title {
        font-family: 'Gotham-Light';
        font-size: .8em;
        padding-top: .25em;
      }
    }

    .teammember-txt-wrapper.white {
      color: $backgroundColor;
    }
  }
}

@media screen and (max-width: 500px) {
  .teammember-wrapper {
    .teammember-image-txt-wrapper {

      .teammember-image, .teammember-image-orange {
        height: 250px;
        width: 325px;
      }

      .teammember-image-orange {
        font-size: .8em;
      }
    }
  }
}