@font-face {
  font-family: 'Gotham-Medium';
  src: local('Gotham-Medium'), url(./assets/fonts/Gotham-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'Gotham-Bold';
  src: local('Gotham-Bold'), url(./assets/fonts/Gotham-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'Gotham-Book';
  src: local('Gotham-Book'), url(./assets/fonts/Gotham-Book.ttf) format('opentype');
}

@font-face {
  font-family: 'Gotham-Light';
  src: local('Gotham-Light'), url(./assets/fonts/Gotham-Light.ttf) format('opentype');
}

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'GothaBoo', 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  text-align: center;
}

h1, h2, div {
  line-height: 1.25em;
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 24px;
    line-height: 1.25em;
  }

  div {
    font-size: 15px;
    line-height: 1.25em;
  }
}